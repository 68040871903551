<template>
  <div class="box" style="background: linear-gradient(180deg, #00B5A0 0%, #0C9793 100%);">
    <div class="top-box">
          <div>
              <p>Hi，{{myInfo.types == '1' ? '乘客': '车主'}}{{myInfo.name}}</p>
              <div class="riqi">
                  <img style="width:18px;height:18px;" src="https://middle-ground.shulanhealth.com/rili-icon%402x.png" alt="">
                  <span>今天是 {{toDay}}，天气{{weatherInfo.weather}}，气温{{weatherInfo.temperature}}°</span>
              </div>
          </div>
          <!-- <img class="head-sty" :src="'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL4Mluv3Vq2pwFZVfbeYUelic7xMjAheDCsQcvGVhG3lg6TpRrEZA7fQJzw2FduyWIGnBYFfbJq7nA/132'" alt=""> -->
      </div>
    <div class="bar-box" style="margin-top:20px;background: #fff;width: 100%;padding:3%;box-sizing: border-box;">
      <p style="text-align:center;">《免责声明》</p>
      <div style="font-size:14px;text-align: center;margin-top: 10px;">
                  请滑动到底部，并选择是否接受本声明
              </div>
              <p>SayHi拼车服务为公众提供方便、快捷、免费的拼车信息，方便于公众，服务于大众。任何使用本平台的用户均应仔细阅读本声明，用户可选择不使用本平台，使用本平台的行为将被视为对本声明全部内容的认可。</p>
              <p>1、本平台用户需为年满 18 周岁的具有完全民事行为能力成年人，未成年人用户需由其监护人许可并全程陪同，使用本平台拼车信息的视为已满 18 周岁的完全民事行为能力人，</p>
              <p>2、本平台为公益平台，平台内各项拼车信息均为网友自行发布，网上信息有风险，在实际拼车过程中，请您务必保持警惕!本平台概不负任何责任。</p>
              <p>3、用户拼车采取自原参加、风险自担、责任自负、费用自理的原则，本平台没有义务也无法做到对参与者的信息及行为进行审查，本平台提供的任何拼车信息不构成法律上的义务，更不构成分担用户任何损失或风险的依据;</p>
              <p>4、为保障车主和乘客双方权益，可以要求对方出示相关身份证明，并在启程前双方协商好各项事宜。在拼车的过程中，无论是开车人还是搭车人，均应注意自身的人身安全。在同意拼车前，各自需要对另一方的情况进行调查，判断，本平台不对任何人提供任何形式的安全担保，一旦发生侵犯人身、财产安全的事件，本平台不承担任责任;</p>
              <p>5、拼车过程中存在很多不可预见的危险，道路行驶、自身身体健康、自然灾害等因素均有可能造成对用户生命财产的伤害和损失。用户应当积极主动的购买保险，降低损失，一日发生事故和人身伤害，由保险公司及事故责任方负责赔偿，本平台不承担任何责任;</p>
              <p>6、本平台用户理解，本平台有权根据法律法规、司法或行政命令以及本平台内部政策情况对平台信息及本声明书进行调整、中止或终止，如因本平台进行调整、中止或终止的，所造成的用户损失本平台不承担任何责任;</p>
              <p>7、本平台保留下述权利:随时修改、删除在本平台发布的任何信息;随时停止本网站提供的服务;公安司法部门在对本平台上出现的不良信息进行调查时，向相关部门提供信息发布者的 IP 地址以及其他信息。</p>
              <p style="text-align:right;">SayHi拼车 2022年11月22日</p>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import AMap from "AMap"; // 引入高德地图
export default {
  components: {
  },
  data() {
    return {
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
      myTx: null
    };
  },

  mounted() {
    document.title = "免责声明";
    this.toDay = dayjs().format('YYYY年MM月DD日')
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types
    this.myInfo = JSON.parse(localStorage.getItem("user"))
    //加载天气查询插件
    let _this = this
    AMap.plugin('AMap.Weather', function() {
        //创建天气查询实例
        var weather = new AMap.Weather();

        //执行实时天气信息查询
        weather.getLive('杭州市', function(err, data) {
            console.log(err, data);
            _this.weatherInfo = data
        });
    });
  },
  methods: {
  },
};
</script>
<style scoped>
/deep/ .van-popup {
  background: none;
}
/deep/ .van-tree-select__nav {
  flex: 0.5;
}
/deep/ .van-info
{
  background: rgb(12, 151, 147);
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;padding-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.dcx {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 999;
  background: rgb(12, 151, 147);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.wdxc {
  position: absolute;
  z-index: 9999;
  color: #fff;
}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
